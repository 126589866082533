<template>
  <div>
    <a-modal
      :title="title"
      :visible="visible"
      :confirm-loading="loading"
      @ok="handleOk"
      @cancel="handleCancel"
      :centered="true"
      :maskClosable="false"
      :width="500"
    >
      <a-form-model
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        ref="form"
        :model="form"
      >
        <a-form-model-item prop="name" label="名称">
          <a-input placeholder="收藏夹名称" v-model="form.name" />
        </a-form-model-item>

        <a-form-model-item prop="description" label="描述">
          <a-input placeholder="描述" v-model="form.description" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'EditFolderModal',

  data () {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 16 },
      // 表单验证规则
      rules: {
        name: [
          { required: true, message: '请输入收藏夹名称', trigger: ['blur', 'change'] },
          { min: 1, max: 20, message: '收藏夹名称长度为1-20', trigger: ['blur', 'change'] }
        ],
        description: [
          { message: '请输入收藏夹描述', trigger: ['blur', 'change'] },
          { min: 0, max: 100, message: '收藏夹描述长度为100以内', trigger: ['blur', 'change'] }
        ]
      },

      // 用于Select的标签选项列表
      tagSelectOptionList: []
    }
  },

  props: {
    title: String,
    visible: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    form: { type: Object, required: true }
  },

  methods: {
    // 确认
    handleOk () {
      this.$emit('ok')
    },

    // 取消
    handleCancel () {
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>

</style>
