// 混入通用状态映射
export default {
  data () {
    return {
      statusMap: {
        AVAILABLE: { text: '可用', color: 'blue' },
        DISABLED: { text: '不可用', color: 'grey' }
      },
      genderMap: {
        M: { text: '男', color: 'blue' },
        F: { text: '女', color: 'red' },
        U: { text: '保密', color: 'grey' }
      },
      superManagerMap: {
        true: { text: '超级管理', color: 'orange' },
        false: { text: '普通管理', color: 'green' }
      },
      tradeStatusMap: {
        Pending: { text: '待支付', color: 'orange', icon: 'clock-circle' },
        Paid: { text: '已支付', color: 'var(--blue-color)', icon: 'check-circle' },
        Canceled: { text: '已取消', color: 'grey', icon: 'close-circle' }
      },
      sourceMap: {
        SYSTEM: { text: '系统', color: 'blue' },
        REFUND: { text: '退款', color: 'orange' },
        Paid: { text: '抵扣支付', color: 'grey' }
      }
    }
  },

  methods: {
    // 获取标签
    getStatusTag (v) {
      const t = this.statusMap[v]
      if (t) return <a-tag color={t.color}>{t.text}</a-tag>
      return <a-tag color='grey'>/</a-tag>
    },
    getGenderTag (v) {
      const t = this.genderMap[v]
      if (t) return <a-tag color={t.color}>{t.text}</a-tag>
      return <a-tag color='grey'>/</a-tag>
    },
    getManagerTypeTag (v) {
      const t = this.superManagerMap[v]
      if (t) return <a-tag color={t.color}>{t.text}</a-tag>
      return <a-tag color='grey'>/</a-tag>
    },
    getTradeStatusTag (v) {
      const t = this.tradeStatusMap[v]
      if (t) return <a-tag color={t.color}>{t.text}</a-tag>
      return <a-tag color='grey'>/</a-tag>
    },
    getSourceMap (v) {
      const t = this.sourceMap[v]
      if (t) return <a-tag color={t.color}>{t.text}</a-tag>
      return <a-tag color='grey'>/</a-tag>
    }
  }
}
