<template>
  <div>
    <!-- 加载中 -->
    <div v-if="isLoading" class="loading">
      <a-icon type="loading" />
    </div>
    <!-- 全部加载完了 -->
    <div v-if="isAllLoaded" class="all-loaded text-not-selected">
      <div class="all-loaded-line"></div>
      <div class="all-loaded-text">没有更多数据了</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'loading',

  props: {
    isLoading: { type: Boolean, default: false },
    isAllLoaded: { type: Boolean, default: false }
  }
}
</script>

<style scoped>
.loading,
.all-loaded {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
  font-size:24px;
  color:#999;
}
.all-loaded-line {
  position: absolute;
  width: 500px;
  height: 1px;
  transform: scaleY(0.5);
  background: rgba(153, 153, 153, 0.5);
  z-index: 9;
}
.all-loaded-text {
  display: inline-block;
  padding: 3px 20px;
  background: var(--bg-color);
  z-index: 10;
  border-radius: 10px;
  font-size: 13px;
}
</style>
