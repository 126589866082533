// 混入表格页面通用的属性
export default {
  data () {
    return {
      // 表格数据
      list: [],

      // 分页相关
      pageNum: 1,
      pageSize: 40,
      total: 0,
      isLoading: true,
      isAllLoaded: false,

      // 筛选相关
      filter: {},

      // 排序相关
      sort: {},
      sortMap: {
        ascend: 'ASC',
        descend: 'DESC'
      }
    }
  },

  methods: {
    // 筛选
    handleFilter (pagination, filters, sorter, { currentDataSource }) {
      if (!this._.isEmpty(filters)) {
        for (const key in filters) {
          this.filter = {
            [key]: filters[key][0]
          }
        }
      }
    },

    // 排序
    handleSort (pagination, filters, sorter, { currentDataSource }) {
      if (!this._.isEmpty(sorter)) {
        this.sort = {
          [sorter.field]: this.sortMap[sorter.order]
        }
      }
    },

    // 重置页面相关
    handleResetPage () {
      // this.list = []
      this.pageNum = 1
      this.total = 0
      this.isLoading = true
      this.isAllLoaded = false
    },

    // 滚动到底触发函数
    onReachBottom (callback) {
      return () => {
        if (this.isAllLoaded) return
        // 变量scrollTop是滚动条滚动时，距离顶部的距离
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        // 变量windowHeight是可视区的高度
        const windowHeight = document.documentElement.clientHeight || document.body.clientHeight
        // 变量scrollHeight是滚动条的总高度
        const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
        // 滚动条到底部的条件
        if (scrollTop + windowHeight >= scrollHeight - 10) {
          this.pageNum++
          this.isLoading = true
          if (typeof (callback) === 'function') callback()
        }
      }
    }
  }
}
