<template>
  <div class="container" :style="{ padding: `${containerPadding}px`, '--count-per-line': countPerLine,
    '--folder-width': `${folderWidth}px`, '--folder-margin': `${folderMargin}px` }">
    <div class="user-info">
      <img alt="avatar" class="user-avatar text-not-selected" :src="userInfo.avatar" />
      <div>{{ userInfo.name }}</div>
      <div>
        <a-button icon="profile" @click="handleGoOrderList" style="margin-right: 10px">订单</a-button>
        <a-button @click="handleOpenUserInfoModal">编辑个人信息</a-button>
      </div>
    </div>
    <div class="operate-line">
      <div></div>
      <a-icon class="add-folder" type="plus" @click="handleShowModal" />
    </div>
    <!-- 收藏夹列表 -->
    <div class="folder-list text-not-selected">
      <!-- 收藏夹 -->
      <a-card v-for="(item, index) of list" class="folder" :key="index" hoverable :style="{ width: `${folderWidth}px` }">
        <template slot="actions">
          <a-icon key="eye" type="eye" @click="handleGoCollection(item.id)" />
          <a-icon key="edit" type="edit" @click="handleShowModal(index)" />
          <a-popconfirm title="是否确认删除？" @confirm="handleDel(index)">
            <a-icon slot="icon" type="question-circle-o" style="color: var(--red-color)" />
            <a-icon key="delete" type="delete" />
          </a-popconfirm>
        </template>
        <a-card-meta :title="item.name" @click="handleGoCollection(item.id)">
          <img
            slot="avatar" style="width: 50px; height: 50px; border-radius: 10px"
            :src="item.images && item.images.length > 0 ? item.images[0]+'?x-oss-process=image/resize,w_50,m_lfit' : require('@/assets/images/logo-default.png')"
          />
        </a-card-meta>
      </a-card>
    </div>

    <!-- 加载提示 -->
    <loading :is-loading="isLoading" :is-all-loaded="isAllLoaded"></loading>

    <!-- 添加和编辑框 -->
    <edit-folder-modal
      ref="modal"
      :title="modalTitle"
      :visible="modalVisible"
      :loading="modalLoading"
      :form="form"
      :isEdit="isEdit"
      @ok="handleAddOrEdit"
      @cancel="handleCloseModal"
    />

    <!-- 编辑用户信息框 -->
    <user-info-modal
      ref="userInfoModal"
      :visible="userInfoModalVisible"
      :form="userInfoForm"
      @ok="handleEditUserInfo"
      @cancel="handleCloseUserInfoModal"
    />
  </div>
</template>

<script>
import page from '@/mixins/page'
import Loading from '@/components/loading/loading'
import EditFolderModal from './EditFolderModal'
import UserInfoModal from './UserInfoModal'
import { copy, deepCopy } from '@/utils/object'

export default {
  name: 'PersonalCenter',

  data () {
    return {
      // 收藏夹宽度
      folderWidth: 300,
      // 收藏夹外边距
      folderMargin: 20,
      // 容器内边距
      containerPadding: 20,
      // 一行几个收藏夹
      countPerLine: 5,

      // modal相关参数
      modalVisible: false,
      modalLoading: false,
      addModalTitle: '添加收藏夹',
      editModalTitle: '编辑收藏夹信息',
      isEdit: false,
      addForm: {
        name: '',
        description: ''
      },
      editForm: {
        id: -1,
        name: '',
        description: ''
      },
      form: {},

      // 用户信息modal相关参数
      userInfoModalVisible: false,
      userInfoForm: {
        name: '',
        gender: '',
        avatar: '',
        password: ''
      }
    }
  },

  computed: {
    // 用户信息
    userInfo () {
      return this.$store.state.userInfo
    },
    modalTitle () {
      return this.isEdit ? this.editModalTitle : this.addModalTitle
    },
    // 获取列表的参数
    args () {
      return {
        page: {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
      }
    }
  },

  components: { Loading, EditFolderModal, UserInfoModal },

  mixins: [page],

  mounted () {
    this.pageSize = 50
    // 检测剩余宽度，并计算出可容纳的宽度
    const remainingWidth = document.documentElement.clientWidth - this.containerPadding * 2
    this.countPerLine = Math.floor(remainingWidth / (this.folderWidth + this.folderMargin))
    // 监听滚动到底的事件
    window.addEventListener('scroll', this.onReachBottom(this.getFolderList))
    this.getFolderList()
  },

  beforeDestroy () {
    removeEventListener('scroll', this.onReachBottom(this.getFolderList))
  },

  methods: {
    // 获取数据
    getFolderList (isLazy = true) {
      this.api.getFolderListByUser(this.args).then(res => {
        if (isLazy) {
          this.list = res.data.getFolderListByUser.data
        } else {
          this.list = this.list.concat(res.data.getFolderListByUser.data)
        }
        this.total = res.data.getFolderListByUser.total
        this.isLoading = false
        this.isAllLoaded = this.list.length >= this.total
      }).catch(() => {
        this.isLoading = false
      })
    },

    // 打开弹框
    handleShowModal (i = -1) {
      if (i >= 0) {
        this.form = deepCopy(this.editForm)
        copy(this.form, this.list[i])
        this.isEdit = true
      } else {
        this.form = deepCopy(this.addForm)
        this.isEdit = false
      }
      this.modalVisible = true
    },

    // 关闭弹框
    handleCloseModal () {
      this.modalVisible = false
      // 去掉提示
      this.$refs.modal.$refs.form.resetFields()
    },

    // 添加或编辑
    handleAddOrEdit () {
      this.$refs.modal.$refs.form.validate(valid => {
        if (!valid) return
        if (this.isEdit) {
          this.handleEdit(deepCopy(this.form))
        } else {
          this.handleAdd(deepCopy(this.form))
        }
      })
    },

    // 添加数据
    handleAdd (form) {
      this.api.addFolderByUser(form).then(res => {
        if (res.data.addFolderByUser.id) {
          this.$message.success('添加成功')
          this.handleResetPage()
          this.getFolderList(true)
          this.handleCloseModal()
        }
      })
    },

    // 编辑数据
    handleEdit (form) {
      this.api.editFolderByUser(form).then(res => {
        try {
          if (res.data.editFolderByUser.rowsAffected >= 0) {
            this.$message.success('编辑成功')
            this.handleResetPage()
            this.getFolderList(true)
            this.handleCloseModal()
            return
          }
          this.$message.error('编辑失败')
        } catch (e) {
          this.$message.error('编辑失败')
        }
      })
    },

    // 删除
    handleDel (index) {
      this.api.delFolderByUser(this.list[index].id).then(res => {
        try {
          const c = res.data.delFolderByUser.rowsAffected
          if (c !== undefined && c >= 0) {
            this.$message.success('删除成功')
            this.handleResetPage()
            this.getFolderList(true)
            return
          }
          this.$message.error('删除失败')
        } catch (e) {
          this.$message.error('删除失败')
        }
      })
    },

    // 查看收藏夹内容
    handleGoCollection (id) {
      this.$router.push({
        path: `/collection/${id}`
      })
    },

    // 打开用户信息弹框
    handleOpenUserInfoModal () {
      copy(this.userInfoForm, this.userInfo)
      this.userInfoModalVisible = true
    },

    // 编辑用户信息
    handleEditUserInfo () {
      this.api.editInfoByUser(this.userInfoForm).then(res => {
        try {
          if (res.data.editInfoByUser.rowsAffected >= 0) {
            this.$message.success('编辑成功')
            this.api.getUserInfo().then(res2 => {
              const info = res2.data.getInfoByUser
              if (!info) return
              this.$store.commit('setUserInfo', info)
            })
            this.handleCloseUserInfoModal()
            return
          }
          this.$message.error('编辑失败')
        } catch (e) {
          this.$message.error('编辑失败')
        }
      })
    },

    // 关闭用户信息弹框
    handleCloseUserInfoModal () {
      this.userInfoModalVisible = false
      // 去掉提示
      this.$refs.userInfoModal.$refs.form.resetFields()
    },

    // 订单页
    handleGoOrderList () {
      this.$router.push({
        path: '/order-list/'
      })
    }
  }
}
</script>

<style scoped>
.container {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 10px;
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
}
.user-avatar {
  width: 150px;
  height: 150px;
  margin: 0;
}

.operate-line {
  display: flex;
  justify-content: space-between;
}
.add-folder {
  font-size: 23px;
}
.add-folder:hover {
  color: var(--blue-color);
  cursor: pointer;
}

.folder-list {
  display: grid;
  grid-template-columns: repeat(var(--count-per-line), var(--folder-width));
  grid-auto-flow: row dense;
  gap: var(--folder-margin);
}
.folder {
  /*margin: 0 10px 10px 0;*/
}

.folder .ant-card-meta-description {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
</style>
